<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar categoría</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        <!-- Name -->
        <vs-input @input="setSlug" data-vv-as="nombre" @blur="category.name= trimString(category.name)" label="Nombre"
                  v-model="category.name"
                  class="w-full" name="name"
                  v-validate="'required|min:3'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Slug -->
        <vs-input disabled @blur="category.slug= trimString(category.slug)" label="Slug" v-model="category.slug"
                  class="mt-5 w-full" name="slug"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('slug')">{{ errors.first('slug') }}</span>

        <!-- Order -->
        <div class="mt-5">
          <vs-input type="number" label="Orden"
                    v-model.number="category.order"
                    class="w-full" name="order"
                    v-validate="'min_value:0'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('order')">{{ errors.first('order') }}</span>
        </div>
        <!--End-->

        <!-- Collections -->
        <div class="mt-5">
          <label class="vs-select--label" for="sections">Colección</label>
          <v-select multiple id="collections" label="name"
                    :options="['Men', 'Women', 'Unisex', 'Packs']"
                    v-model="category.collections"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="collections"/>
        </div>
        <!--End-->

        <!-- Description -->
        <vs-input data-vv-as="descripción" @blur="category.description= trimString(category.description)"
                  label="Descripción"
                  v-model="category.description" class="mt-5 w-full" name="description"
                  v-validate="'min:5'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('description')">{{ errors.first('description') }}</span>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import vSelect from 'vue-select'

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
export default {
  name: 'UpdateCategory',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    fathers: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data() {
    return {
      category: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.category = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          const obj = {
            ...this.category,
            uid: firebase.auth().currentUser.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }
          // Update category
          db.collection('categories').doc(this.category.id).update({
            ...obj
          }).then(() => {
            this.$store.dispatch('categories/updateCategories', {category: obj})
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Categoría',
              text: 'Categoría modificada correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    setSlug() {
      this.category.slug = this.getSlug(this.category.name)
    },
    getSlug(name) {
      let text = (name).trim().split(' ').join('-').toLowerCase().normalize('NFD')
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
          .normalize();
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
